import * as React from 'react'
import Layout from '/src/components/layout'
import Anvandarvillkor from '/src/components/villkor'

const VillkorPage = () => {
	return (
		<Layout pageTitle="Villkor & personuppgifter">

            <p className="intro">Brfify har schyssta villkor och på denna sida finns dem i sin helhet.</p>

			<p>&nbsp;</p>
            
			<Anvandarvillkor></Anvandarvillkor>
        
		</Layout>
	)
}

export default VillkorPage